<template>
  <AffiliateLayout>
    <b-tabs>
      <b-tab title="Chung">
        <b-card
          no-body
          title="Thiết lập hoa hồng cho danh mục"
        >
          <div
            class="d-flex align-items-center p-2"
          >
            <div class="d-flex justify-content align-items-center w-25">
              <b-form-group
                :label="`*` + $t('Category') + `:`"
                label-cols-md="12"
                class="w-100"
              >
                <v-select
                  v-model="model.list"
                  :options="categoryOptions"
                  :reduce="(option) => option.id_number"
                  label="name"
                  input-id="invoice-data-client"
                  :clearable="false"
                  class=""
                  multiple
                  placeholder="Chọn danh mục"
                />
              </b-form-group>
            </div>
            <div class="d-flex justify-content align-items-center mx-2">
              <b-form-group
                :label="`*` + $t('Percent') + `:`"
                label-cols-md="12"
              >
                <b-input-group
                  append="%"
                  class="mx-auto"
                >
                  <b-form-input
                    v-model="model.percent_discount"
                    type="number"
                    :placeholder="$t('Percent')"
                    @keyup="checkPercentDiscount"
                  />
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="px-2">
            <b-button @click="handleSubmit">
              Thêm mới
            </b-button>
          </div>
          <b-card-body class="mt-2 p-0">
            <vue-good-table
              ref="promotion-products"
              class="custom-good-table custom-good-table-ctrl-border commission"
              :columns="fields"
              :rows="items"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span>
                  {{ $t(props.column.label) }}
                </span>
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span
                  v-if="props.column.field === 'commission_product'"
                  class="flex-wrap"
                >
                  <b-badge
                    v-for="(item, index) in props.row.commission_product"
                    :key="index"
                    variant="light-dark mr-1"
                  >
                    {{ item.category.name }}
                  </b-badge>
                </span>

                <!-- Column: percent_discount -->
                <span v-else-if="props.column.field === 'percent_discount'">
                  {{ props.row.percent_discount }} %
                </span>

                <!-- Column: fixed_costs -->
                <!-- <span v-else-if="props.column.field === 'fixed_costs'">
                  {{ unitPrice(props.row.fixed_costs) }}
                </span> -->

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'is_active'">
                  <ActiveStatus
                    class="d-flex flex-wrap align-items-center justify-content-center"
                    :value="props.row.is_active"
                  />
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'more'">
                  <div class="text-danger d-flex flex-wrap align-items-center justify-content-center">
                    <b-button
                      variant="flat-dark"
                      class="btn-icon rounded-circle"
                      @click="showModal(props.row)"
                    >
                      <feather-icon icon="Edit3Icon" />
                    </b-button>
                    <b-button
                      variant="flat-danger"
                      class="text-danger btn-icon rounded-circle"
                      @click="handlerDelete(props.row.id)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                </span>

              </template>
              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap ctrl-pd-1-5 p-2">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5','10','20']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ totalRows }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-tab>
      <b-tab title="Sản phẩm đặc biệt">
        <commission-products />
      </b-tab>
    </b-tabs>
    <!-- modal -->
    <b-modal
      ref="my-modal"
      title="Chỉnh sửa cài đặt hoa hồng cho danh mục"
      size="lg"
      no-close-on-backdrop
    >
      <div class="commission-cat-edit">
        <div class="xt">
          <div
            class="d-flex align-items-center commission-cat-form"
          >
            <div class="d-flex justify-content align-items-center w-50 commission-cat-input">
              <b-form-group
                :label="`*` + $t('Category') + `:`"
                label-cols-md="12"
                class="w-100"
              >
                <v-select
                  v-model="modelEdit.list"
                  :options="catEditOptions"
                  :reduce="(option) => option.id_number"
                  label="name"
                  input-id="invoice-data-client"
                  :clearable="false"
                  class=""
                  multiple
                  placeholder="Chọn danh mục"
                />
              </b-form-group>
            </div>
            <div class="d-flex justify-content align-items-center w-50 commission-cat-input">
              <b-form-group
                :label="`*` + $t('Percent') + `:`"
                label-cols-md="12"
                class="w-100"
              >
                <b-input-group
                  append="%"
                  class="mx-auto"
                >
                  <b-form-input
                    v-model="modelEdit.percent_discount"
                    type="number"
                    :placeholder="$t('Percent')"
                    @keyup="checkPercentDiscountEdit"
                  />
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer="{ cancel }">
        <b>Lưu cài đặt</b>
        <b-button
          size="sm"
          variant="success"
          @click="handleSubmitEdit"
        >
          Lưu
        </b-button>

        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Hủy
        </b-button>
      </template>
    </b-modal>
  </AffiliateLayout>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BCardBody,
  // BCardHeader,
  BButton,
  BPagination,
  BFormSelect,
  BFormInput,
  BFormGroup,
  BBadge,
  BInputGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'
import Vue from 'vue'
import MixinAffiliate from '../mixin'
import CommissionProducts from './Product.vue'
import AffiliateLayout from '../layouts/AffiliateLayout.vue'

Vue.mixin(MixinAffiliate)

export default {
  components: {
    // ValidationObserver,
    BCard,
    BTabs,
    BTab,
    BCardBody,
    // BCardHeader,
    BButton,
    BPagination,
    BFormSelect,
    BFormInput,
    BFormGroup,
    BBadge,
    vSelect,
    VueGoodTable,
    CommissionProducts,
    AffiliateLayout,
    BInputGroup,
  },
  data() {
    return {
      unitPrice: MixinAffiliate.unitFormatOriginal,
      required,
      model: {
        list: [],
        type: 1,
        percent_discount: 0,
        fixed_costs: 0,
      },
      categoryOptions: [],
      fields: [
        {
          field: 'commission_product', label: 'Category', tdClass: 'text-left w-50', thClass: 'text-left', sortable: false,
        },
        {
          field: 'percent_discount', label: 'Percent', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'created_at', label: 'Created at', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'more', label: 'Tool', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
      ],
      items: [],
      pageLength: 10,
      currentPage: 1,
      totalRows: 20,
      isEdit: false,
      cats: [],
      catEditOptions: [],
      modelEdit: {
        list: [],
        type: 1,
        percent_discount: 0,
        fixed_costs: 0,
      },
    }
  },
  watch: {
    pageLength() { this.loadCommission() },
    currentPage() { this.loadCommission() },
  },
  created() {
    this.loadCategory()
    this.loadCommission()
  },
  methods: {
    showModal(data) {
      this.catEditOptions = []
      this.isEdit = true
      const ids = data.commission_product.map(x => x.category_id)
      this.modelEdit = {
        id: data.id,
        fixed_costs: data.fixed_costs,
        percent_discount: data.percent_discount,
        type: data.type,
        list: ids,
      }
      this.cats.map(val => {
        if (val.is_commission === 0 || ids.includes(val.id_number)) {
          this.catEditOptions.push(val)
        }
        return true
      })
      this.$refs['my-modal'].show()
    },
    checkPercentDiscount() {
      this.model.percent_discount = Number(this.model.percent_discount)
      if (this.model.percent_discount > 100) {
        this.model.percent_discount = 100
      }
      if (this.model.percent_discount < 0) {
        this.model.percent_discount = 0
      }
    },
    checkPercentDiscountEdit() {
      this.modelEdit.percent_discount = Number(this.modelEdit.percent_discount)
      if (this.modelEdit.percent_discount > 100) {
        this.modelEdit.percent_discount = 100
      }
      if (this.modelEdit.percent_discount < 0) {
        this.modelEdit.percent_discount = 0
      }
    },
    handleShowEdit(data) {
      this.isEdit = true
      const ids = data.commission_product.map(x => x.category_id)
      this.model = {
        id: data.id,
        fixed_costs: data.fixed_costs,
        percent_discount: data.percent_discount,
        type: data.type,
        list: ids,
      }
    },
    async handleSubmitEdit() {
      const params = {
        fixed_costs: Number(this.modelEdit.fixed_costs),
        percent_discount: Number(this.modelEdit.percent_discount),
        type: 1,
        list: JSON.stringify(this.modelEdit.list),
        site_id: JSON.parse(localStorage.getItem('siteID')).id_number,
        is_active: 1,
      }
      const res = await MixinAffiliate.put(`/api/v1/commission/${this.modelEdit.id}`, params)
      if (res.status) {
        this.modelEdit.list = []
        this.modelEdit.percent_discount = null
        this.modelEdit.fixed_costs = null
        this.loadCommission()
        this.loadCategory()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Congratulation !',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Update Comission success',
          },
        })
        this.$refs['my-modal'].hide()
      }
    },
    // eslint-disable-next-line consistent-return
    async handleSubmit() {
      if (Number(this.model.percent_discount) === 0 && Number(this.model.fixed_costs) === 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Congratulation !',
            icon: 'CheckCircleIcon',
            variant: 'danger',
            text: 'Phần trăm phải khác 0',
          },
        })
        return false
      }
      if (this.model.list.length === 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: 'Vui lòng chọn danh mục sản phẩm',
          },
        })
        return false
      }
      const params = {
        fixed_costs: Number(this.model.fixed_costs),
        percent_discount: Number(this.model.percent_discount),
        type: 1,
        list: JSON.stringify(this.model.list),
        site_id: JSON.parse(localStorage.getItem('siteID')).id_number,
        is_active: 1,
      }
      const res = await MixinAffiliate.post('/api/v1/commission', params)
      if (res.status) {
        this.loadCategory()
        this.model.list = []
        this.model.percent_discount = null
        this.model.fixed_costs = null
        this.loadCommission()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Congratulation !',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Create Comission success',
          },
        })
      }
    },
    async loadCommission() {
      const params = {
        limit: this.pageLength,
        page: this.currentPage,
        type: '1',
        'sort[]': 'created_at,desc',
        site_id: JSON.parse(localStorage.getItem('siteID')).id_number,
      }
      const res = await MixinAffiliate.get('/api/v1/commissions', params)
      if (res.status) {
        this.totalRows = res.data.total
        this.items = res.data.items
      }
    },
    async loadCategory() {
      const params = {
        limit: '1000',
      }
      const res = await this.mixGet('/categories', params)
      if (res.status) {
        // eslint-disable-next-line camelcase
        const findData = res.data.items.filter(({ is_commission }) => is_commission === 0)
        this.cats = res.data.items
        this.categoryOptions = findData
      }
    },
    async handlerDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id_number,
            }
            const res = await MixinAffiliate.delete(`/api/v1/commission/${id}`, params)
            if (res.status) {
              this.loadCommission()
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Commission has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.custom-good-table.commission {
  .flex-wrap {
    display: flex;
    gap: 1rem 0;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.custom-good-table-ctrl-border{
  .vgt-table{
    border: 1px solid #fff !important;
  }
  td{
    padding: 0.75rem !important;

    &:first-child {
      padding-left: 1.5rem !important;
    }
    &:last-child {
      padding-right: 1.5rem !important;
    }
  }
  thead{
    background-color: #f3f2f7
  }
}
.commission-cat-edit{
  .commission-cat-form{
    gap: 15px;
  }
}
</style>
