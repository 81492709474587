<template>
  <b-card
    class="commission-prod-block"
    no-body
  >
    <div class="p-2">
      <h4>Chọn sản phẩm</h4>
      <div
        class="d-flex align-items-center"
      >
        <div class="d-flex justify-content align-items-center w-25 mr-2">
          <b-form-group
            :label="`*` + $t('Category') + `:`"
            label-cols-md="12"
            class="w-100 mb-0"
          >
            <v-select
              v-model="category_id"
              :options="categoryOptions"
              :reduce="(option) => option.id_number"
              label="name"
              input-id="invoice-data-client"
              :clearable="false"
              class=""
              placeholder="Chọn danh mục"
            />
          </b-form-group>
        </div>
        <div class="d-flex justify-content align-items-center w-75">
          <b-form-group
            :label="`*` + $t('Products') + `:`"
            label-cols-md="12"
            class="w-100 mb-0"
          >
            <v-select
              v-model="model.list_product"
              :options="items"
              :reduce="(option) => option"
              label="name"
              input-id="invoice-data-client"
              :clearable="false"
              class=""
              multiple
              placeholder="Chọn sản phẩm"
              @search="searchProducts"
            />
          </b-form-group>
        </div>
      </div>
    </div>

    <b-card-body class="p-0">
      <div
        id="promotionRole"
        :title="$t('Advanced Option')"
        class="px-2"
      >
        <!-- Advance -->
        <div class="advance-block commission-advance">
          <div class="commission-edit-head">
            <span>Thiết lập % chiếc khấu hàng loạt</span>
            <div
              class="v-input-group v-item-percent"
            >
              <span class="v-input-label">%</span>
              <input
                v-model="limitOn.percent"
                type="number"
                class="v-input"
                @input="checkPercent()"
              >
            </div>
            <b-button
              variant="gradient-primary"
              size="sm"
              @click="advanceApply"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Apply') }}</span>
            </b-button>
          </div>

          <div class="commission-edit-head">
            <b-button
              variant="gradient-danger"
              size="sm"
              :disabled="model.selected.length === 0"
              @click="advanceDelete"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Delete') }}</span>
            </b-button>
            <span><b>{{ model.selected.length }}</b> sản phẩm đã chọn</span>
          </div>
        </div>
        <ProductItems
          v-model="model"
        />
      </div>
      <div class="p-2">
        <b-button @click="validationForm">
          Thêm mới
        </b-button>
      </div>
      <div>
        <h4 class="p-2">
          Danh sách sản phẩm
        </h4>
        <vue-good-table
          ref="promotion-products"
          class="custom-good-table custom-good-table-ctrl-border commission"
          :columns="fields"
          :rows="productCommissions"
          :pagination-options="{
            enabled: true,
            perPage:paramProCm.limit
          }"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              {{ $t(props.column.label) }}
            </span>
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'commission_product'"
              class="flex-wrap"
            >
              <b-badge
                v-for="(item, index) in props.row.commission_product"
                :key="index"
                variant="light-dark mr-1"
              >
                {{ item.category.name }}
              </b-badge>
            </span>

            <!-- Column: percent_discount -->
            <span v-else-if="props.column.field === 'percent_discount'">
              {{ props.row.percent_discount }} %
            </span>

            <!-- Column: name -->
            <div v-else-if="props.column.field === 'name'">
              <div class="commission-item-prod">
                <div class="commission-item-prod-img">
                  <img
                    height="50"
                    :src="props.row.avatar"
                    alt=""
                  >
                </div>
                <div class="commission-item-prod-info">
                  <div><b>{{ props.row.name }}</b></div>
                  <div class="commission-item-prod-info-cat">
                    <b-badge
                      v-for="(item, index) in props.row.category"
                      :key="index"
                      variant="light-primary"
                    >
                      {{ item.name }}
                    </b-badge>
                  </div>
                  <div>
                    <small>{{ props.row.sku }}</small>
                  </div>
                </div>
              </div>
            </div>

            <!-- Column: fixed_costs -->
            <span v-else-if="props.column.field === 'fixed_costs'">
              {{ unitPrice(props.row.fixed_costs) }}
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'is_active'">
              <ActiveStatus
                class="d-flex flex-wrap align-items-center justify-content-center"
                :value="props.row.is_active"
              />
            </span>

            <!-- Column: commission -->
            <span
              v-else-if="props.column.field === 'commission'"
              class="commission-group"
            >
              <div
                v-if="props.row.warehouse[0] && props.row.warehouse[0].commission_product"
              >
                {{ unitFormatOriginal(props.row.warehouse[0].commission_product.fixed_costs + ((props.row.warehouse[0].price * props.row.warehouse[0].commission_product.percent_discount) / 100)) }}
              </div>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'more'">
              <div class="text-danger d-flex flex-wrap align-items-center justify-content-center">
                <b-button
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  @click="showModal(props.row)"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>
                <b-button
                  variant="flat-danger"
                  class="text-danger btn-icon rounded-circle"
                  @click="handlerDelete(props.row.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </span>

          </template>
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap ctrl-pd-1-5 p-2">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="paramProCm.limit"
                  :options="['5','10','20']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ paramProCm.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  v-model="paramProCm.page"
                  :total-rows="paramProCm.total"
                  :per-page="paramProCm.limit"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="loadProductCommission()"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card-body>

    <!-- modal -->
    <b-modal
      ref="my-modal"
      :title="`Chỉnh sửa: ${prodCmDetail.name}`"
      size="lg"
    >
      <div class="commission-edit">
        <div class="commission-edit-head">
          <span>Thiết lập % chiếc khấu hàng loạt</span>
          <div
            class="v-input-group v-item-percent"
          >
            <span class="v-input-label">%</span>
            <input
              v-model="prodCmDetailPercent"
              type="number"
              class="v-input"
              @input="checkProdCmDetailPercent()"
            >
          </div>
          <b-button
            size="sm"
            @click="setProdCmDetailPercent()"
          >
            Áp dụng
          </b-button>
        </div>
        <div class="custom-good-table custom-good-table-ctrl-border vgt-wrap">
          <table class="vgt-table bordered">
            <thead>
              <tr>
                <th>Hình</th>
                <th>Biến thể</th>
                <th>Giá gốc</th>
                <th>% Chiếc khấu</th>
                <th>Hoa hồng</th>
                <th>Số lượng kho</th>
                <th>Thao tác</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in prodCmDetail.warehouse"
                :key="index"
              >
                <td>
                  <img
                    :height="50"
                    :src="item.avatar"
                    alt="..."
                  >
                </td>
                <td>{{ attrFix(item.name_attribute) }}</td>
                <td>{{ unitFormatOriginal(item.price) }}</td>
                <td>
                  <div
                    v-if="item.commission_product"
                    class="v-input-group v-item-percent"
                  >
                    <span class="v-input-label">%</span>
                    <input
                      v-model="prodCmDetail.warehouse[index].commission_product.percent_discount"
                      type="number"
                      class="v-input"
                      @input="checkPercentDetail(index)"
                    >
                  </div>
                </td>
                <td>
                  <b v-if="item.commission_product">{{ unitFormatOriginal(item.commission_product.fixed_costs + ((item.price * item.commission_product.percent_discount) / 100)) }}</b>
                </td>
                <td>{{ item.quantity_stock }}</td>
                <td>
                  <b-form-checkbox
                    v-if="prodCmDetail.warehouse[index].commission_product"
                    v-model="prodCmDetail.warehouse[index].commission_product.is_active"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
      <template #modal-footer="{ cancel }">
        <b>Lưu cài đặt</b>
        <b-button
          size="sm"
          variant="success"
          @click="editProdCm"
        >
          Lưu
        </b-button>

        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Hủy
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BButton,
  BPagination,
  BFormSelect,
  BFormGroup,
  BBadge,
  BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'
import Vue from 'vue'
import ProductItems from './ProductItems.vue'
import MixinAffiliate from '../mixin'

Vue.mixin(MixinAffiliate)

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BPagination,
    BFormSelect,
    BFormGroup,
    BBadge,
    vSelect,
    VueGoodTable,
    ProductItems,
    BFormCheckbox,
  },
  data() {
    return {
      unitPrice: MixinAffiliate.unitFormatOriginal,
      prodCmDetailPercent: 0,
      required,
      model: {
        list: [],
        type: 1,
        list_product: [],
        content: null,
        apply_all_product: 0,
        selectedAll: false,
        selected: [],
        validCheckAll: 0,
      },
      categoryOptions: [],
      fields: [
        {
          field: 'name', label: 'Tên sản phẩm', tdClass: 'text-left', thClass: 'text-left', sortable: false,
        },
        {
          field: 'sku', label: 'SKU', tdClass: 'text-left', thClass: 'text-left', sortable: false,
        },
        {
          field: 'category_name', label: 'Danh mục', tdClass: 'text-left', thClass: 'text-left', sortable: false,
        },
        {
          field: 'commission', label: 'Hoa hồng', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'quantity_stock', label: 'Số lượng kho', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
        {
          field: 'more', label: 'Tool', tdClass: 'text-center', thClass: 'text-center', sortable: false,
        },
      ],
      items: [],
      productCommissions: [],
      limitOn: {
        promotion: null,
        person: null,
        promotionQuantity: null,
        personQuantity: null,
        percent: 0,
        discount: 0,
      },
      category_id: null,
      timeout: null,

      paramProCm: {
        'sort[]': 'id,desc',
        limit: 10,
        page: 1,
        total: 0,
        is_commission: 1,
      },
      prodCmDetail: {},
    }
  },

  watch: {
    category_id() { this.loadListProd(this.category_id) },
  },
  created() {
    this.loadCategory()
    this.loadListProd()
    this.loadProductCommission()
  },
  methods: {
    async editProdCm() {
      const resF = true
      try {
        const params = {}
        const warehouseIdList = []
        let numEr = 0

        this.prodCmDetail.warehouse.map(val2 => {
          if (!val2.commission_product?.percent_discount) {
            numEr += 1
          }
          warehouseIdList.push({
            id: val2.commission_product?.id,
            warehouse_id: val2.id,
            percent_discount: val2.commission_product?.percent_discount,
            fixed_costs: val2.commission_product?.fixed_costs,
            product_id: this.prodCmDetail.id,
            is_active: val2.commission_product?.is_active ? 1 : 0,
          })
          return resF
        })

        if (numEr !== 0) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String('Invalid product list'),
            },
          })
          this.model.validCheckAll = Math.random()
          return resF
        }

        params.warehouse_list = JSON.stringify(warehouseIdList)

        const res = await MixinAffiliate.put('/api/v1/commission_product_update_multi', params)
        if (res.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Update Commission success',
            },
          })
          this.loadProductCommission()
          this.$refs['my-modal'].hide()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return resF
    },
    setProdCmDetailPercent() {
      this.prodCmDetail.warehouse.map((val, index) => {
        if (this.prodCmDetail.warehouse[index].commission_product) {
          this.prodCmDetail.warehouse[index].commission_product.percent_discount = this.prodCmDetailPercent
        }
        return true
      })
    },
    showModal(data) {
      this.prodCmDetail = JSON.parse(JSON.stringify(data))
      this.$refs['my-modal'].show()
    },
    checkProdCmDetailPercent() {
      this.prodCmDetailPercent = Number(this.prodCmDetailPercent)
      if (this.prodCmDetailPercent > 100) this.prodCmDetailPercent = 100
      if (this.prodCmDetailPercent < 0) this.prodCmDetailPercent = 0
    },
    checkPercent() {
      this.limitOn.percent = Number(this.limitOn.percent)
      if (this.limitOn.percent > 100) this.limitOn.percent = 100
      if (this.limitOn.percent < 0) this.limitOn.percent = 0
    },
    checkPercentDetail(index) {
      this.prodCmDetail.warehouse[index].commission_product.percent_discount = Number(this.prodCmDetail.warehouse[index].commission_product.percent_discount)
      if (this.prodCmDetail.warehouse[index].commission_product.percent_discount > 100) {
        this.prodCmDetail.warehouse[index].commission_product.percent_discount = 100
      }
      if (this.prodCmDetail.warehouse[index].commission_product.percent_discount < 0) {
        this.prodCmDetail.warehouse[index].commission_product.percent_discount = 0
      }
    },
    attrFix(jsonArrt) {
      let kq = ''
      const attr = JSON.parse(jsonArrt)
      try {
        kq = attr.map(a => a.value).join('-')
      } catch (error) { kq = '' }
      return kq
    },
    advanceApply() {
      if (this.model.selected.length > 0) {
        this.model.selected.map(val => {
          const findData = this.model.list_product.find(x => x.id === val)
          if (findData) {
            findData.children.map((val2, index) => {
              findData.children[index].percent = this.limitOn.percent
              findData.children[index].discount = this.limitOn.discount
              return true
            })
          }
          return true
        })
      } else {
        this.model.list_product.map((val, index) => {
          const findData = this.model.list_product[index]
          findData.limitProducts = this.limitOn.personQuantity
          findData.isLimitProduct = this.limitOn.person
          findData.children.map((val2, index2) => {
            findData.children[index2].percent = this.limitOn.percent
            findData.children[index2].discount = this.limitOn.discount
            return true
          })
          return true
        })
      }
      this.model.validCheckAll = Math.random()
    },
    advanceDelete() {
      const temp = JSON.parse(JSON.stringify(this.model.selected))
      temp.map(id => {
        this.model.list_product.splice(this.model.list_product.find(val => val.id === id), 1)
        return true
      })
      this.model.selected = []
      this.model.selectedAll = false
    },
    searchProducts() {
      clearTimeout(this.timeout)
      this.timeout = (async () => {
        this.loadListProd()
      }, 200)
    },
    async loadListProd(data = null) {
      const resF = true
      try {
        const params = {
          'sort[]': 'id,desc',
          limit: '30',
          is_commission: '0',
        }
        if (this.category_id) {
          params.category_id = `[${this.category_id}]`
        }
        if (this.data) {
          params.name = data
        }
        const res = await MixinAffiliate.get('/api/v1/auth/products', params)
        if (res.status) {
          this.items = []
          this.productListTemp = []
          res.data.items.map(val => {
            const warehouse = []
            val.warehouse.map(val2 => {
              let temp = []
              try {
                temp = JSON.parse(val2.name_attribute || '[]')
              } catch (error) { return resF }
              let nameAttr = ''
              temp.map(val3 => {
                nameAttr += `${val3.value}-`
                return resF
              })
              nameAttr = nameAttr.slice(0, -1)
              const valid = {
                show: false,
                status: true,
                warning: false,
                ok_name: '',
                ok_inventory: '',
                ok_price: '',
                ok_discount: '',
                ok_percent: '',
                ok_limit_warehouse: '',
                ok_is_limit_warehouse: '',
                ok_status: '',
              }
              warehouse.push({
                id: val2.id,
                name: nameAttr,
                id_prod: val.id_number,
                inventory: val2.quantity_stock,
                price: val2.price,
                discount: 0,
                percent: 0,
                limit_warehouse: 0,
                is_limit_warehouse: null,
                status: !valid.warning,
                is_active: true,
                valid,
              })
              return resF
            })
            const item = {
              mode: 'span',
              avatar: val.avatar,
              created_at: val.created_at,
              id: val.id_number,
              is_active: val.is_active,
              name: val.name,
              position: val.position,
              sku: val.sku,
              category_slug: val.category_slug,
              list_id_promotion: val.list_id_promotion,
              list_promotion: val.list_promotion,
              isLimitProduct: null,
              limitProducts: 0,
              isLimitOnPerson: null,
              limit_products_on_person: null,
              discount_price: val.price,
              discount_percent: 0,
              label: val.name,
              children: warehouse,
              warehouse: val.warehouse,
            }
            this.items.push(item)
            this.productListTemp.push(item)
            return resF
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadProductCommission() {
      try {
        const params = {
          ...this.paramProCm,
        }
        const res = await MixinAffiliate.get('/api/v1/auth/products', params)
        if (res.status) {
          this.paramProCm.total = res.data.total
          this.productCommissions = []
          res.data.items.map((val, index) => {
            const warehouse = []
            val.warehouse.map((val2, i) => {
              let temp = []
              try {
                temp = JSON.parse(val2.name_attribute || '[]')
              } catch (error) { return false }
              let nameAttr = ''
              temp.map(val3 => {
                nameAttr += `${val3.value}-`
                return true
              })
              nameAttr = nameAttr.slice(0, -1)
              warehouse.push({
                ...val2,
                id: val2.id,
                name: nameAttr,
                id_prod: val.id_number,
                inventory: val2.quantity,
                price: val2.price,
                discount: 0,
                percent: 0,
                limit_warehouse: 0,
                is_limit_warehouse: null,
              })
              if (val.warehouse[i].commission_product) {
                res.data.items[index].warehouse[i].commission_product.is_active = val2.commission_product.is_active === 1
              }
              return true
            })
            const item = {
              ...val,
              avatar: val.avatar,
              created_at: val.created_at,
              id: val.id_number,
              is_active: val.is_active,
              name: val.name,
              position: val.position,
              sku: val.sku,
              children: warehouse,
              warehouse: res.data.items[index].warehouse,
              category_name: val.category.map(a => a.name).join(', '),
            }
            this.productCommissions.push(item)
            return true
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      const resF = true
      try {
        const params = {}
        const warehouseIdList = []
        let numEr = 0
        this.model.list_product.map((val, index) => {
          const findData = this.model.list_product[index]

          findData.children.map(val2 => {
            if (!val2.percent) {
              numEr += 1
            }
            if (val2.valid.status === true) {
              warehouseIdList.push({
                warehouse_id: val2.id,
                percent_discount: val2.percent,
                fixed_costs: val2.discount,
                product_id: val.id,
                is_active: val2.is_active ? 1 : 0,
              })
            } else {
              numEr += 1
            }
            return resF
          })
          return resF
        })

        if (numEr !== 0) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String('Invalid product list'),
            },
          })
          this.model.validCheckAll = Math.random()
          return false
        }
        params.warehouse_list = JSON.stringify(warehouseIdList)

        if (warehouseIdList.length === 0) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String('No products selected yet'),
            },
          })
          return resF
        }

        const res = await MixinAffiliate.post('/api/v1/commission_product_multi', params)
        if (res.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Create Commission success',
            },
          })
          this.model.list_product = []
          this.loadProductCommission()
          this.loadListProd()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return resF
    },
    async loadCategory() {
      const params = {
        limit: '1000',
        is_commission: '1',
      }
      const res = await this.mixGet('/categories', params)
      if (res.status) {
        this.categoryOptions = res.data.items
      }
    },
    async handlerDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id_number,
              product_id: id,
            }
            const res = await MixinAffiliate.delete('/api/v1/commission_product_delete_multi', params)
            if (res.status) {
              this.loadProductCommission()
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Commission has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.commission-prod-block{
  .custom-good-table.commission {
    .flex-wrap {
      display: flex;
      gap: 1rem 0;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .custom-good-table-ctrl-border{
    .vgt-table{
      border: 1px solid #fff !important;
    }
    td{
      padding: 0.75rem !important;

      &:first-child {
        padding-left: 1.5rem !important;
      }
      &:last-child {
        padding-right: 1.5rem !important;
      }
    }
    thead{
      background-color: #f3f2f7
    }
  }
  .advance-block {
    background: #F6F6F6;
    padding: 1rem;

    .form-group {
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;
    }
    .form-group {
      height: 100%;

      > div {
        margin-top: auto;
      }
    }
    .limit-form-group {
      label {
        max-width: 130px;
      }
      > div {
        display: grid;
        grid-template-columns: 1fr 60px;
      }
      select {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
    }
  }

  .commission-item-prod{
    display: flex;

    .commission-item-prod-img{
      width: 70px;
      img{
        width: 100%;
        height: auto;
      }
    }
    .commission-item-prod-info{
      padding-left: 10px;

      .commission-item-prod-info-cat{
        display: flex;
        gap: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        .badge{
          border-radius: 0;
          padding: 3px 7px;
        }
      }
    }
  }
}

.commission-edit{
  table{
    text-align: center;
  }
  .vgt-table{
    font-size: 13px;
  }
  .v-item-percent{
    position: relative;
    max-width: 65px;
    margin: 0 auto;
    .v-input-label{
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 11px;
    }
    .v-input,
    .v-input:active,
    .v-input:focus{
      padding-right: 20px;
      width: 100%;
      border: none;
      border-bottom: 1px solid #ddd;
      outline: none;
      box-shadow: none;
    }
  }

  .commission-edit-head{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    .v-item-percent{
      margin: unset;
    }
  }

  .custom-good-table{
    overflow-y: hidden;
  }
}

.commission-advance{
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;

  .v-item-percent{
    position: relative;
    max-width: 65px;
    margin: 0 auto;

    .v-input-label{
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 11px;
    }

    .v-input,
    .v-input:active,
    .v-input:focus{
      padding-right: 20px;
      width: 100%;
      border: none;
      border-bottom: 1px solid #ddd;
      outline: none;
      box-shadow: none;
    }
  }

  .commission-edit-head{
    display: flex;
    align-items: center;
    gap: 10px;

    .v-item-percent{
      margin: unset;
    }
  }

  .custom-good-table{
    overflow-y: hidden;
  }
}
</style>
